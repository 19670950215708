<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{ zIndex: options.zIndex }"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>
      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">조직 생성|공유 안내</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container grid-list-xs class="pb-0 mb-0">
          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" xs="12">
              <v-text-field
                v-model="form.wname"
                v-validate="'required|max:25'"
                :error-messages="errors.collect('wname')"
                data-vv-name="wname"
                required
                placeholder="입력하세요"
                name="wname"
                label="✶ 조직명"
                type="text"
                class="mx-2"
              ></v-text-field>
              <v-text-field
                v-model="form.addr1"
                v-validate="'required|min:1|max:150'"
                :error-messages="errors.collect('addr1')"
                data-vv-name="addr1"
                required
                placeholder="입력하세요"
                name="addr1"
                label="✶ 주소"
                type="text"
                class="mx-2"
              ></v-text-field>
              <v-text-field
                v-model="form.tel"
                v-validate="{required: true, regex: [/^\d{2,3}-\d{3,4}-\d{4}$/]}"
                :error-messages="errors.collect('tel')"
                data-vv-name="tel"
                required
                placeholder="02-123-4567"
                hint="02-123-4567"
                name="tel"
                label="✶ 전화번호"
                type="text"
                class="mx-2"
              ></v-text-field>
              <v-text-field
                v-model="form.fax"
                v-validate="{regex: [/^\d{2,3}-\d{3,4}-\d{4}$/]}"
                :error-messages="errors.collect('fax')"
                data-vv-name="fax"
                name="fax"
                label="팩스번호"
                placeholder="02-123-4567"
                hint="02-123-4567"
                type="text"
                class="mx-2"
              ></v-text-field>
              <v-text-field
                v-model="form.mname"
                name="mname"
                label="관리자"
                placeholder="홍길동"
                type="text"
                class="mx-2"
              ></v-text-field>
              <v-text-field
                :value="form.email"
                label="관리자이메일"
                filled
                shaped
                disabled
                class="mx-2"
              ></v-text-field>
              <!-- <div class="subheading mt-1 mb-2">
                <span class="font-weight-regular mr-3">관리자계정</span>
                <span class="font-weight-bold blue--text ml-2">{{ form.email }}</span>
              </div> -->
            </v-col>
            <v-col cols="12" xs="12">
              <!-- <v-divider class="white--text"></v-divider> -->
              <v-card class="elevation-0 mt-3 mb-3 blue-grey--text">
                <v-card-text>
                  <p>조직을 생성시키는 계정을 "관리자계정"이라고 합니다.</p>
                  <p>구글에서 본 계정을 삭제하면, 본 솔루션의 모든 데이터도 영구히 삭제됩니다.
                     또한, 해당 계정의 구글 드라이브의 지정 폴더 및 문서를 삭제하거나 경로 변경시에 정상적인 이용이 불가능합니다.
                  </p>
                  <p>그러므로,  관리자계정을 선택하는 것에 신중하기 바랍니다.</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </baseModal>

    <v-dialog
      v-model="loading"
      fullscreen
    >
      <v-container fluid fill-height style="background-color: rgba(115, 115, 115, 0.5);">
        <v-layout justify-center align-center>
          <v-progress-circular
          :size="150"
          :width="10"
          color="primary"
          indeterminate></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'

export default {
  components: {
    baseModal
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    dialog: false,
    loading: false,
    resolve: null,
    reject: null,
    options: {
      color: 'primary',
      width: 650,
      zIndex: 300
    },

    form: {
      email: '',
      wname: '',
      addr1: '',
      tel: '',
      fax: '',
      mname: ''
    },
    dictionary: {
      messages: ko.messages,
      attributes: {
        wname: '조직명',
        addr1: '주소',
        tel: '전화번호',
        fax: '팩스번호'
      }
    }
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    this.initForm() // 폼 초기화

    if (this.$store.state.ui) {
      // 이미 로그인된 상태라 저장소에 유저정보가 저장된 경우
      this.dialog = true

      // 이메일과 운영관리자 이름은 저장된 토큰에서 가져온다.
      this.form.email = this.$store.state.ui.email
      this.form.mname = this.$store.state.ui.name
    } else {
      // 이도저도 아니면 뭔가 잘못되었으니 초기화면으로
      this.redirect('/')
    }
  },

  methods: {
    dummy () {
    },
    sbpop (msg) {
      this.$store.commit('SB_POP', { msg })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 폼 초기화
    initForm () {
      this.form = {
        email: '',
        wname: '',
        addr1: '',
        tel: '',
        fax: '',
        mname: ''
      }
    },
    async agree () {
      try {
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')
        // console.log(this.form)

        this.dialog = false
        this.loading = true

        const { data } = await this.$axios.post('auth/setLawWorkInfo', this.form)
        if (!data.success) {
          throw new Error('조직생성에 실패했습니다')
        } else {
          this.loading = false

          // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
          const target = 'confirmDialog'
          const pop = await this.findParentRefs(this.$parent, target)
          if (!pop) throw new Error('팝업창을 열 수 없습니다.')
          // 찾았으면 팝업을 연다
          const msg = `축하합니다. 이제 <b>${this.form.wname}</b>을(를) 생성하였습니다.<br>관리자의 승인메일을 받으신 후 사용가능합니다.`
          if (await pop.open('성공', msg, { color: 'green', width: 380 })) {
            // ! [2022.3.15] 로그아웃 시킨다. 이후 초기 화면으로 보낸다.
            this.$store.dispatch('LOGOUT').then(() => this.$router.push(`/member/waitAdmit?email=${this.form.email}&officeName=${this.form.wname}`))
            // this.redirect('/member/firstLogin')
            // this.redirect('/guide') // [2021.1.27]이용가이드로 변경
          } else {
            // ! [2022.3.15] 에러상황: 로그아웃 시킨다. 이후 초기 화면으로 보낸다.
            this.$store.dispatch('LOGOUT').then(() => this.$router.push('/'))
            // this.redirect('/member/firstLogin')
            // this.redirect('/guide') // [2021.1.27]이용가이드로 변경
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    cancel () {
      // 취소시 앞 페이지로 돌아간다
      this.initForm() // 폼 초기화
      this.redirect('/auth/regist2')
      this.dialog = false
    },
    redirect (to = '') {
      this.dialog = false
      this.$router.push(to)
    }
  }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem
}
</style>
